.home {
    @media (max-height: 420px) and (orientation: landscape) {
        display: none;
    }
}

.rotate {
    display: none;

    @media (max-height: 420px) and (orientation: landscape) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        padding: 50px 0;
    }

    .phone {
        height: 200px;
        width: 100px;
        border: 3px solid black;
        border-radius: 10px;
        animation: rotate 1.5s ease-in-out infinite alternate;
        z-index: 100;
    }

    .message {
        color: $font-main;
        font-size: 20px;
        margin-top: 20px;
        text-transform: uppercase;
        font-weight: bold;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(-90deg);
        }
        100% {
            transform: rotate(-90deg);
        }
    }
}

.slogan {
    font-size: 50px;
    z-index: 1;
    position: relative;
    font-family: 'Epilogue', sans-serif;
    font-weight: 800;
    line-height: 55px;
    margin: 0;

    @media (max-width: 1075px) {
        font-size: 45px;
        line-height: 50px;
    }

    @media (max-width: 995px) {
        font-size: 40px;
        line-height: 45px;
    }

    @media (max-width: 885px) {
        font-size: 35px;
        line-height: 40px;
    }
}

.about-btn {
    margin-top: 20px;
    z-index: 1;
    position: relative;
    font-size: 18px;
    transition: 0.5s;
    border: none;
}

$height: 16px;
$halfheight: ($height/2);
$thickness: 125%; // increase to make the line thicker
$offset: 4px;

.zigzag {
    position: relative;
    height: $height;
    z-index: 1;
    margin-top: -20px;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
    }
    &:before {
        height: ($height - $offset);
        top: $thickness;
        background: linear-gradient(-135deg, $font-main $halfheight, transparent 0) 0 $halfheight,
            linear-gradient(135deg, $font-main $halfheight, transparent 0) 0 $halfheight;
        background-position: top left;
        background-repeat: repeat-x;
        background-size: $height $height;
    }
    &:after {
        height: $height;
        top: 100%;
        background: linear-gradient(-135deg, $background $halfheight, transparent 0) 0 $halfheight,
            linear-gradient(135deg, $background $halfheight, transparent 0) 0 $halfheight;
        background-position: top left;
        background-repeat: repeat-x;
        background-size: $height $height;
    }
}

.top {
    z-index: 100;
}

.bottom {
    z-index: 0;
}

.img-home {
    height: 84%;
    position: absolute;

    @media (max-width: 1120px) {
        height: 80%;
    }

    @media (max-width: 1050px) {
        height: 73.5%;
    }

    @media (max-width: 910px) {
        height: 71%;
    }

    @media (max-width: 910px) {
        height: 70%;
    }

    @media (max-width: 850px) {
        height: 65%;
    }

    @media (max-width: 810px) {
        height: 63%;
    }

    @media (max-width: 790px) {
        height: auto;
        width: 100%;
        position: relative;
        top: -35px;
        margin-bottom: -35px;
    }
}

.home-section {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (max-width: 790px) {
        height: auto;
    }

    .img-home {
        margin-top: -40px;
    }
}

.home-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.home-column {
        @media (max-width: 790px) {
            flex-direction: column;
            height: auto;
        }
    }

    &.about-column {
        @media (max-width: 790px) {
            flex-direction: column;
            height: auto;
        }
    }

    &.examples-column {
        @media (max-width: 790px) {
            flex-direction: column;
            height: auto;
        }
    }
}

.home-top {
    height: 100%;
    padding-top: 80px;
}

.home-right,
.home-left {
    height: 100%;
}

.home-right {
    width: 58%;
    padding: 70px 0 50px 0;
    display: flex;
    justify-content: end;
    align-items: flex-end;

    @media (max-width: 790px) {
        width: 100%;
        height: auto;
        padding: 0;
    }

    .form-txt-small {
        width: 95%;
        font-size: 17.5px;
        text-shadow: 0.2px 0 $font-main;

        @media (max-width: 1150px) {
            font-size: 17px;
        }

        @media (max-width: 1000px) {
            width: 100%;
        }

        @media (max-width: 320px) {
            font-size: 16px;
        }
    }

    .form-txt-big {
        font-family: 'Epilogue', sans-serif;
        font-size: 30px;
        margin-bottom: 15px;
        font-weight: 700;
        width: 65%;

        @media (max-width: 790px) {
            width: 100%;
        }

        @media (max-width: 375px) {
            margin-bottom: 10px;
        }

        @media (max-width: 320px) {
            margin-bottom: 5px;
        }
    }

    .action-btn {
        background-color: #ceac03;
        align-self: center;
        cursor: pointer;
        transition: 0.4s;
        padding: 15px 35px;
        font-size: 14px;
        letter-spacing: -0.1px;
        width: auto;
        color: $font-main;
        margin-top: 20px;
        border: none;

        &:hover {
            background-color: lighten(#ceac03, 5%);
        }

        @media (max-width: 375px) {
            margin: 10px 0 15px -1px;
            padding: 10px 25px;
        }

        @media (max-width: 320px) {
            padding: 5px 15px;
        }
    }
}

.home-left {
    width: 38%;
    padding: 70px 0 50px 0;

    @media (max-width: 790px) {
        padding-top: 25px;
        padding-bottom: 70px;
        width: 100%;
    }

    @media (max-width: 510px) {
        width: 100%;
        padding: 45px 0px 70px;
    }

    @media (max-width: 420px) {
        padding: 45px 0px 70px;
    }

    @media (max-width: 320px) {
        padding: 45px 0px 70px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    margin-top: 50px;
    width: 50%;
    z-index: 1;

    @media (max-width: 1000px) {
        width: 40%;
    }

    @media (max-width: 800px) {
        width: 50%;
    }

    @media (max-width: 750px) {
        margin-top: 60px;
        width: 90%;
    }

    @media (max-width: 700px) {
        margin-top: 40px;
    }

    @media (max-width: 420px) {
        margin-left: 0px;
        width: 100%;
    }

    .form-txt-small {
        margin-bottom: 20px;
    }

    .form-txt-big {
        font-family: 'Epilogue', sans-serif;
        font-size: 25px;
        margin-bottom: 10px;
        font-weight: 500;
    }

    .form-info {
        font-size: 12px;
        margin-top: 10px;
    }

    input {
        border: 2px solid $lightblue-darker;
        padding: 10px;
        background-color: transparent;
        font-family: 'Oswald', sans-serif;
        color: $font-main;
        outline: none;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    input::placeholder {
        color: $font-main;
    }

    .email-input {
        width: 300px;
    }

    .form-btn {
        cursor: pointer;
        transition: 0.4s;
        background-color: $lightblue-darker;

        &:hover {
            background-color: lighten($lightblue-darker, 5%);
        }

        @supports (-webkit-touch-callout: none) {
            font-size: 16px;
            border-radius: 0;

            @media (max-width: 420px) {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }
    }
}

.scroll-btn {
    position: absolute;
    bottom: 0;
    bottom: 10px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: $font-main;
    align-self: center;
    text-align: center;

    @media (max-width: 790px) {
        display: none;
    }
}

.checkbox-label {
    font-size: 13px;
    display: flex;
    align-items: center;

    a {
        margin-left: 3px;
    }
}

.privacy-txt {
    color: $orange;
    font-weight: bold;
    margin-top: 10px;
}

.success-txt {
    color: #62847b;
    font-weight: bold;
}

.home-link {
    color: $font-main;
    text-decoration: none;
    font-weight: bold;
}

.checkbox {
    --background: transparent;
    --border: #799da8;
    --border-hover: #799da8;
    --border-active: #799da8;
    --tick: #799da8;
    position: relative;

    input,
    svg {
        width: 18px;
        height: 18px;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0 5px 0 0;
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: box-shadow 0.3s;
        box-shadow: inset 0 0 0 var(--s, 2px) var(--b, var(--border));
        &:hover {
            --s: 2px;
            --b: var(--border-hover);
        }
        &:checked {
            --b: var(--border-active);
        }
    }

    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        left: 0;
        width: 18px;
        height: 18px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }

    &.path {
        input {
            &:checked {
                --s: 2px;
                transition-delay: 0.4s;
                & + svg {
                    --a: 16.1 86.12;
                    --o: 102.22;
                }
            }
        }
        svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
        }
    }
}
