.compact-picker {
    border: 2px solid $font-main;
    background-color: $background;
    box-shadow: rgba(255, 255, 255, 1) 0px 2px 10px, rgba(255, 255, 255, 1) 0px 2px 5px;
}

.create-container {
    padding-top: 80px;
    padding-bottom: 80px;

    @media (max-width: 420px) {
        padding: 80px 10px 35px;
    }

    .error {
        font-weight: bold;
        color: $orange;
    }
}

.create-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1235px) {
        flex-direction: column-reverse;
    }
}

.create-btn {
    background-color: $buttonblue;
    color: $font-main;
    padding: 10px;
    cursor: pointer;
    transition: 0.4s;
    width: 200px;
    border: none;

    &:hover {
        background-color: lighten($buttonblue, 5%);
    }

    @media (max-width: 540px) {
        align-self: center;
    }

    @media (max-width: 420px) {
        margin-bottom: 30px;
    }
}

.font-btn,
.template-btn {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    border: 2px solid $font-main;
    margin: 10px 10px 0 0;
    text-transform: lowercase;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-out;
    padding: 0;
}

.template-btn {
    background-size: cover;
    border-radius: 50px;
}

.radius-btn {
    border: 2px solid black;
    padding: 2px 10px;
    font-size: 12px;
    margin: 5px 10px 0 0;
}

.form-container {
    width: 43%;

    @media (max-width: 1235px) {
        width: 80%;
    }

    @media (max-width: 1235px) {
        width: 80%;
    }

    @media (max-width: 970px) {
        width: 100%;
    }

    @media (max-width: 540px) {
        display: flex;
        flex-direction: column;
    }
}

.preview-container {
    position: fixed;
    right: 70px;

    @media (max-width: 1235px) {
        position: relative;
        right: 0px;
    }

    @media (max-width: 725px) {
        display: none;
    }
}

.preview-display-info {
    display: none;

    p {
        color: #c76325;
        font-weight: bold;
    }

    @media (max-width: 725px) {
        display: block;
    }
}

.section-txt {
    font-size: 35px;
    margin: 40px 0 8px;
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
}

.web-content {
    min-height: 200px;
    border: 1px solid $font-main;
    padding: 0 15px;
}

.create-form-info {
    font-size: 11px;
    color: rgb(102, 102, 102);
    margin-top: 0px;

    a {
        text-decoration: none;
    }
}

.question-container {
    display: flex;
    align-items: center;
}

.txt-smaller {
    display: block;
    margin: 10px 0 -7px;
    font-size: 15px;
}

.form-label {
    display: block;
    font-size: 14px;
    margin: 15px 0 5px;
    margin-right: 5px;
}

.templates-container {
    display: flex;

    @media (max-width: 420px) {
        flex-wrap: wrap;
    }
}

.template-name {
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 13px;
    transition: all 0.2s ease-out;
    width: 40px;
    line-height: 11px;
    cursor: pointer;
}

.input-label {
    @media (max-width: 360px) {
        font-size: 16px;
    }
}

.form-input,
.input-txt,
.msg-txt,
.input-label,
.msg-input {
    display: block;
    padding-top: 10px;
    border: none;
    font-size: 22px;

    &::placeholder {
        opacity: 1;
        color: gray;
    }
}

.msg-input:focus,
.form-input:focus {
    outline: 0;
}

/* Question */

.msg-input {
    resize: none;
    padding-top: 0;
}

.form-input,
.msg-input {
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 300;
    border-radius: 2px;
    margin: 0;
    border: none;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    transition: padding-top 0.2s ease, margin-top 0.2s ease;
    overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */

    @media (max-width: 585px) {
        font-size: 21px;
    }

    @media (max-width: 420px) {
        font-size: 19px;
    }

    @media (max-width: 375px) {
        font-size: 17px;
    }

    @media (max-width: 360px) {
        font-size: 16px;
    }
}
/* Underline and Placeholder */

.form-input + .input-label,
.msg-input + .msg-label {
    display: block;
    position: relative;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    width: 10%;
    border-top: 2px solid #e05915;
    -webkit-transition: width 0.4s ease;
    transition: width 0.4s ease;
    height: 0px;
}

.form-input:focus + .input-label,
.msg-input:focus + .msg-label {
    width: 80%;
}

.form-input:focus + .input-label > .input-txt,
.form-input:valid + .input-label > .input-txt,
.form-input:invalid + .input-label > .input-txt {
    top: -80px;
    font-size: 18px;
    font-weight: 500;
    z-index: -1;

    @media (max-width: 360px) {
        font-size: 17px;
    }
}

.msg-input:focus + .msg-label > .msg-txt,
.msg-input:valid + .msg-label > .msg-txt,
.msg-input:invalid + .msg-label > .msg-txt {
    top: -115px;
    font-size: 18px;
    font-weight: 500;
    z-index: -1;

    @media (max-width: 360px) {
        font-size: 17px;
    }
}

.form-input:valid + .input-label,
.msg-input:valid + .msg-label {
    border-color: #799da8;
}

.form-input:invalid,
.msg-input:invalid {
    box-shadow: none;
}

.form-input + .input-label > .input-txt,
.msg-input + .msg-label > .msg-txt {
    font-weight: 300;
    margin: 0;
    position: absolute;
    font-size: 22px;
    top: -40px;
    left: 0px;
    z-index: -1;
    -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
    transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

.subsection-title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;

    @media (max-width: 360px) {
        font-size: 17px;
    }
}
