.pricing-container {
    height: 100vh;
    padding-top: 100px;
    padding-bottom: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 1050px) {
        padding: 120px 50px 70px;
        height: 100%;
    }

    @media (max-width: 510px) {
        padding-top: 125px;
    }

    @media (max-width: 420px) {
        padding: 120px 30px 70px;
    }

    @media (max-width: 320px) {
        padding: 120px 20px 70px;
    }

    .check-img {
        min-height: 15px;
        min-width: 15px;
        max-height: 15px;
        max-width: 15px;
        margin: 5px 8px 0 0;
        align-self: flex-start;
    }
}

.pricing-wrapper {
    display: flex;
    width: 70%;

    @media (max-width: 1050px) {
        flex-direction: column;
    }

    @media (max-width: 850px) {
        width: 80%;
    }

    @media (max-width: 750px) {
        width: 90%;
    }

    @media (max-width: 700px) {
        width: 100%;
    }

    .currencies {
        display: flex;
        width: 240px;
        margin-top: -0.5px;
        justify-content: center;

        .currency-btn {
            width: 32.5px;
            height: 32.5px;
            font-size: 18px;
            font-weight: bold;
            margin: 0 2px;
            color: $font-main;
        }
    }

    .txt {
        font-size: 12px;
        text-align: center;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        align-items: center;
    }

    .txt-big {
        font-size: 30px;
        font-family: 'Epilogue', sans-serif;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 25px;

        @media (max-width: 420px) {
            font-size: 28px;
        }
    }

    .pricing {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 250px;

        @media (max-width: 1050px) {
            max-width: 100%;
            margin-left: 0;
        }

        @media (max-width: 670px) {
            margin: 25px 0 0;
        }
    }

    .price-container {
        display: flex;
        height: 205px;
        width: 205px;
        flex-direction: column;
        justify-content: space-between;

        .circle {
            height: 200px;
            width: 200px;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: bold;
            border: 10px solid $font-main;
        }

        .circle-color {
            position: absolute;
            background-color: #e2be0b;
            z-index: -1;
            margin-left: 15px;
            margin-top: 10px;
            border: none;
            height: 190px;
            width: 190px;
        }
    }

    .price {
        font-size: 30px;
        letter-spacing: 1.5px;
        font-weight: 800;
    }
}

.btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 15px 0;

    .start-btn {
        background-color: $buttonblue;
        cursor: pointer;
        transition: 0.4s;
        padding: 10px 50px;
        font-size: 13px;
        letter-spacing: -0.1px;
        color: $font-main;
        border: none;

        &:hover {
            background-color: lighten($buttonblue, 5%);
        }

        @media (max-width: 320px) {
            width: 170px;
            padding: 10px 0;
        }
    }

    @media (max-width: 420px) {
        margin: 10px 0 25px;
    }
}
