.account-container {
    padding-top: 100px;
    padding-bottom: 80px;
    height: 100%;
    flex-direction: column;

    @media (max-width: 420px) {
        padding-top: 90px;
        padding-bottom: 100px;
    }

    .title {
        font-size: 45px;
        font-family: 'Epilogue', sans-serif;
        font-weight: 700;
        letter-spacing: 2px;

        @media (max-width: 535px) {
            margin-top: 30px;
            font-size: 35px;
        }

        @media (max-width: 420px) {
            margin-top: 40px;
        }

        @media (max-width: 360px) {
            font-size: 30px;
        }
    }

    .header {
        font-size: 25px;
        font-weight: bold;
        margin: 20px 0 10px;
    }

    .payment-info-container {
        display: flex;
        margin-top: 25px;
        align-items: center;
    }

    .payment-info {
        font-weight: bold;
        color: $orange;
    }

    .payment-btn {
        background-color: $orange;
        cursor: pointer;
        transition: 0.4s;
        padding: 10px 20px;
        font-size: 13px;
        letter-spacing: -0.1px;
        margin-left: 10px;
        color: $background;
        border: none;

        &:hover {
            background-color: darken($orange, 10%);
        }

        @media (max-width: 420px) {
            padding: 5px 10px;
        }

        @media (max-width: 320px) {
            font-size: 12px;
        }
    }

    .info-small {
        font-size: 14px;
        margin-top: 5px;
    }

    .resign-btn {
        background-color: transparent;
        border: 2px solid $font-main;
        color: $font-main;
        margin: 20px 10px 0 0;
        padding: 10px;
        height: 44px;
        max-width: 180px;
        min-width: 180px;

        &:hover {
            background-color: $font-main;
            color: $background;
        }

        @supports (-webkit-touch-callout: none) {
            font-size: 16px;
            padding: 5px 10px;
            max-width: 130px;
            min-width: 130px;
        }
    }

    .manage-btn {
        background-color: $buttonblue;
        border-color: $buttonblue;
        color: $font-main;

        &:hover {
            background-color: lighten($buttonblue, 5%);
            border-color: lighten($buttonblue, 5%);
            color: $font-main;
        }
    }

    .pricing-info {
        margin-top: 20px;

        a {
            text-decoration: none;
            color: $font-main;
            font-weight: bold;
        }
    }

    .sub-status {
        color: $orange;
        font-weight: bold;
    }

    .delete-wrapper {
        margin: 30px 0 15px;

        .resign-btn {
            margin: 10px 0 0 -2px;
            max-width: auto;
            min-width: auto;
        }

        input {
            border: 2px solid $font-main;
            padding: 10px;
            height: 44px;
            background-color: transparent;
            font-family: 'Oswald', sans-serif;
            color: $font-main;
            outline: none;
            text-transform: uppercase;
            width: 200px;
            margin-top: 25px 0 10px;
            font-size: 13px;

            @media (max-width: 420px) {
                padding: 8.5px 10px;
            }

            @media (max-width: 320px) {
                font-size: 12px;
            }

            @supports (-webkit-touch-callout: none) {
                font-size: 16px;
                background-clip: padding-box;
                border-radius: 0;
                padding: 5px 5px 5px 10px;
            }
        }

        input::placeholder {
            color: $font-main;
        }

        .delete-error {
            color: $orange;
            font-weight: bold;
            margin: 5px 0 7px;
        }
    }

    .btns {
        display: flex;
        flex-direction: column;
    }

    .btns:last-child {
        @media (max-width: 420px) {
            margin-bottom: 25px;
        }
    }

    .acc-faq-container {
        display: flex;
        margin-bottom: 15px;
        flex-direction: column;

        .acc-faq-wrapper {
            font-size: 15px;
        }

        .faq-question {
            font-weight: bold;
            margin: 15px 0 5px;
            font-size: 17px;

            @media (max-width: 690px) {
                margin-top: 15px;
            }
        }
    }

    .faq-link {
        font-weight: bold;
        color: $font-main;
    }
}
